import {
  FiLogOut,
} from 'react-icons/fi';
import { motion } from 'framer-motion';
import { ReactElement, SetStateAction, useEffect, useState } from 'react';
import ArrowIcon from '@/public/assets/svg/arrow-down.svg';
import ProfileIcon from '@/public/assets/svg/profile.svg';
import { ProfileMenuStyle } from './style';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { RootState } from '../../redux/store';
import { logOut } from '../../redux/slices/authSlice';

const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.1,
    },
  },
};

const iconVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: 'beforeChildren',
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: 'afterChildren',
    },
  },
};

const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};

const Option = ({ text, icon, setOpen, handleClick }: { text: string, icon: ReactElement, handleClick: () => void, setOpen: React.Dispatch<SetStateAction<boolean>> }) => {
  return (
    <motion.li
      variants={itemVariants}
      onClick={() => {
        handleClick();
        setOpen(false);
      }}
      className='menuOption'
    >
      <motion.span variants={actionIconVariants} >
        {icon}
      </motion.span>
      <span >{text}</span>
    </motion.li>
  );
};

const ProfileMenu = () => {
  const [open, setOpen] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const logout = () => dispatch(logOut());

  const userRole = user?.role;
  const [userUrl, setUserUrl] = useState('');

  useEffect(() => {
    switch (userRole) {
      case 'client':
        setUserUrl(process.env.NEXT_PUBLIC_CLIENT_URL as string);
        break;
      case 'service provider':
        setUserUrl(process.env.NEXT_PUBLIC_OWNER_URL as string);
        break;
      default:
        setUserUrl('https://www.filmmakersmart.com');
        break;
    }
  }, [userRole]);

  return (
    <ProfileMenuStyle>
      <motion.div animate={open ? 'open' : 'closed'} className='framerClass'>
        <button
          onClick={() => setOpen((pv) => !pv)}
          className="profileWrapper"
          style={{ margin: 0 }}
        >
          <span className="profileIcon"><ProfileIcon className='userIcon' /> {user?.firstName || 'user'}</span>
          <motion.span variants={iconVariants}>
            <ArrowIcon className='arrowIcon' />
          </motion.span>
        </button>
        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: 'top', translateX: '-50%' }}
          className='menuOptions'
        >
          <Option setOpen={setOpen} icon={<MdOutlineDashboardCustomize />} text="Dashboard" handleClick={() => window.location.href = `${userUrl}`} key={1} />
          <Option setOpen={setOpen} icon={<FiLogOut />} text="Logout" handleClick={logout} key={2} />
        </motion.ul>
      </motion.div>
    </ProfileMenuStyle>
  );
};



export default ProfileMenu;

